import { VegaBox, VegaFont } from '@heartlandone/vega-react';

import { CardHeaderTypes } from 'types/app';

interface Props {
  children: any;
  type?: CardHeaderTypes;
}

const LayoutCardHeader: React.FC<Props> = ({ children, type = CardHeaderTypes.Secondary }) => {
  return (
    <VegaBox
      style={{
        marginTop: '.5rem',
        marginBottom: '2rem',
        overflowWrap: 'break-word',
      }}
    >
      <VegaFont color={type === CardHeaderTypes.Secondary ? 'text-secondary' : 'text-primary'} variant="font-h-modal">
        {children}
      </VegaFont>
    </VegaBox>
  );
};

export default LayoutCardHeader;
