import { useContext, useMemo } from 'react';

import { VegaThemeManager } from '@heartlandone/vega';
import { VegaBox, VegaFont } from '@heartlandone/vega-react';
import { VegaMuiThemeTypes } from 'theme/theme';

import { UserContext } from 'components/user/auth/userContext';
import { getInitials } from 'utility/helper/helperFunctions';

import styles from './index.module.css';

interface Props {
  theme: VegaMuiThemeTypes;
  size?: 'Large' | 'Medium' | 'Small' | 'WelcomeCard';
}

const UserNameIcon: React.FC<Props> = ({ size }) => {
  const userContext = useContext(UserContext);

  const initials = useMemo(() => getInitials(userContext), [userContext]);

  const getStyle = () => {
    switch (size) {
      case 'WelcomeCard': {
        return styles.userNameIconWelcomeCard;
      }
      case 'Large': {
        return styles.userNameIconLarge;
      }
      case 'Medium': {
        return styles.userNameIconMedium;
      }
      case 'Small': {
        return styles.userNameIconSmall;
      }
      default: {
        return styles.userNameIconMedium;
      }
    }
  };

  return (
    <VegaBox className={getStyle()} backgroundColor="bg-accent5-primary" data-testid="icon-name-user">
      <VegaFont color={VegaThemeManager.isDarkMode() ? 'text-primary' : 'text-inverted-primary'}>
        {initials.toUpperCase() ?? '??'}
      </VegaFont>
    </VegaBox>
  );
};

export default UserNameIcon;
