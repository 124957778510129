import { CallToActionNames, NotificationActions, NotificationResult } from 'types/notification';

import apiFunctions from '../..';

const NotificationBaseUrl = '/api/v1/Notification/';

export const NotificationUrls = {
  getNotifications: NotificationBaseUrl + 'GetNotifications',
  dismissCallToAction: NotificationBaseUrl + 'DismissCallToAction',
  sendAudienceActivity: NotificationBaseUrl + 'SendAudienceActivity',
};

export async function GetNotifications(pathName: string): Promise<NotificationResult> {
  return await apiFunctions.get(NotificationUrls.getNotifications, { pathName });
}

export async function DismissCallToAction(callToAction: CallToActionNames) {
  return await apiFunctions.post(NotificationUrls.dismissCallToAction, { callToActionName: callToAction });
}

export async function SendAudienceActivity(
  action: NotificationActions,
  bannerId: string,
  origin: string,
  pathName: string,
  bannerType: string,
) {
  return await apiFunctions.post(NotificationUrls.sendAudienceActivity, {
    action,
    bannerId,
    origin,
    pathName,
    bannerType,
  });
}

const notificationService = {
  GetNotifications,
  DismissCallToAction,
  SendAudienceActivity,
};

export default notificationService;
